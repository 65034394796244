import React from "react"
import Content from '../templates/content'
import styled from 'styled-components'
import IconEmail from '../../static/assets/icons/email.inline.svg'
import { motion } from 'framer-motion'
import bp from '../assets/styles/breakpoints'
import SEO from '../components/Navigation/seo'
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"


const EmailLink = styled.a`
	color: ${p => p.theme.color.primary};
	margin-top: 3rem;
`;

const EmailIconBox = styled.div`
	width: 4rem;
	line-height: 1;
	margin-right: 1rem;
	${bp.down('sm')}{
		width: 2.5rem;
	}
`;

const List = styled(motion.ul)`
	text-align: left;
	max-width: 32rem;
	margin: 0 auto;
	li{
		margin-bottom: 1rem;
		display: flex;
		&::before{
			content: '–––';
			color: ${p => p.theme.color.primary};
			margin-right: 1rem;
		}
	}
	${bp.down('sm')}{
		padding: 0 2rem;
	}
`;

const list = { visible: { transition: { staggerChildren: 0.12, delayChildren: 0.5 } } }
const item = { hidden: { x: -100, opacity: 0 }, visible: { x: 0, opacity: 1 } }

const ContactPage = () => {
	const intl = useIntl();
	const title = () => <><span className="text-primary d-block"><FormattedMessage id="kontakt.title_1" /></span> <FormattedMessage id="kontakt.title_2" /></>;
	return (
		<Content h1={title()} h1class="mt-3 mt-md-0" hideOnMobile={true}>
			<SEO
				lang={intl.locale}
				title={intl.formatMessage({ id: "kontakt.seoTitle" })}
				description={intl.formatMessage({ id: "kontakt.seoDesc" })}
			/>
			<List animate="visible" initial="hidden" variants={list}>
				{Array(4).fill(null).map((value, index) => (
					<motion.li variants={item} key={index}><FormattedMessage id={'kontakt.list.'+index} /></motion.li>
				))}
			</List>
			<EmailLink href="mailto:biuro@rafalkorzen.pl" className="d-flex justify-content-center align-items-center">
				<EmailIconBox><IconEmail /></EmailIconBox>
				<h2 className="resFont-2">biuro@rafalkorzen.pl</h2>
			</EmailLink>
		</Content>
	)
}


export default ContactPage
